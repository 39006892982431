<template>
  <router-view />
</template>

<script setup>
import pageConfig from '@/assets/page-config.json';
import navConfig from '@/assets/nav-config.json';
import barracuda from '@/assets/board-config/barracuda.json';
import barracudaYouth from '@/assets/board-config/barracuda-youth.json';
import rave from '@/assets/board-config/rave.json';
import rebel from '@/assets/board-config/rebel.json';
import rodeo from '@/assets/board-config/rodeo.json';
import bullit from '@/assets/board-config/bullit.json';
import vegasFoil from '@/assets/board-config/vegas-foil.json';
import vegasTwin from '@/assets/board-config/vegas-twin.json';
import sunsetSport from '@/assets/board-config/sunset-sport.json';
import rideSport from '@/assets/board-config/ride-sport.json';
import rideSchool from '@/assets/board-config/ride-school.json';
import { useStore } from "vuex";
import { provide } from 'vue';

const store = useStore();

provide('navConfig', navConfig);
provide('barracuda', barracuda);
provide('barracuda-youth', barracudaYouth);
provide('rave', rave);
provide('rebel', rebel);
provide('rodeo', rodeo);
provide('bullit', bullit);
provide('vegas-foil', vegasFoil);
provide('vegas-twin', vegasTwin);
provide('sunset-sport', sunsetSport);
provide('ride-sport', rideSport);
provide('ride-school', rideSchool);


</script>
