import { createRouter, createWebHistory, RouterView } from "vue-router";
import store from "@/store/index";
import Translation from "@/i18n/translations";

const routes = [
  {
    path: "/moulay-form",
    name: "moulay-form",
    component: () => import("@/pages/MoulayForm.vue"),
  },
  {
    path: "/:locale?",
    component: () => import("@/layouts/MainLayout.vue"),
    beforeEnter: Translation.routeMiddleware,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
        meta: {
          topLevel: true,
        },
      },
      {
        path: "range-overview",
        name: "range-overview",
        component: () => import("@/pages/RangeOverview.vue"),
      },
      {
        path: "windsurf-boards/:type",
        name: "windsurf-boards",
        component: () => import("@/views/BoardsView.vue"),
      },
      {
        path: "sails",
        name: "sails",
        component: () => import("@/pages/Sails.vue"),
      },
      {
        path: "masts",
        name: "masts",
        component: () => import("@/pages/Masts.vue"),
      },
      {
        path: "booms",
        name: "booms",
        component: () => import("@/pages/Booms.vue"),
      },
      {
        path: "accessoires",
        name: "accessoires",
        component: () => import("@/pages/Accessoires.vue"),
      },
      {
        path: "about-concept",
        name: "about-concept",
        component: () => import("@/pages/AboutConcept.vue"),
      },
      {
        path: "team",
        name: "team",
        component: () => import("@/pages/Team.vue"),
      },
      {
        path: "distributors",
        name: "distributors",
        component: () => import("@/pages/Distributors.vue"),
      },
      {
        path: "construction",
        name: "construction",
        component: () => import("@/pages/Construction.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta?.requiresAuth)) {
//     if (to.meta.requiresInternalValidation) {
//       store.commit("setFormInvalid");
//     } else {
//       store.commit("setFormValid");
//     }
//     const token = sessionStorage.getItem("token");
//     if (!sessionStorage.getItem("token")) {
//       next({
//         path: "/login",
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
